.tippy-box[data-theme~='light'] {
  background-color: white;
  color: #333;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  border: 1px solid #e2e8f0;
  font-size: 12px;
  max-width: min(580px, calc(100vw - 130px)) !important; /* Adapt to viewport but max 600px */

  .tippy-content {
    padding: 10px;
  }

  .tippy-svg-arrow {
    display: none;
  }
}

/* Synchronized hover effect for citations with the same index */
span[data-citation-index].citation-hovered,
a[data-citation-index].citation-hovered {
  color: #2563eb !important; /* blue-600 */
}
